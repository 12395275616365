import app from '@/main'

const add = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const res = await app.$http.post(`/v2/apps/${param.application}/${type}`, param.payload)
    return { success: true, data: res }
  } catch (e) {
    return {
      success: false,
      data: e.response?.data?.errors?.map((error) => ('detail' in error ? error.detail : error))
    }
  }
}

const items = async (param) => {
  try {
    const limit = `?limit=${param.paging && param.paging.limit ? param.paging.limit : 100}`
    const offset = param.paging && param.paging.offset ? `&offset=${param.paging.offset}` : ''
    const filter =
      'filter' in param && param.filter !== null && param.filter !== undefined
        ? `&${param.filter}`
        : ''
    const res = await app.$http.get(
      `/v2/apps/${param.application}/${param.type}/${limit}${offset}${filter}`
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const item = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const config = 'config' in param && param.config ? param.config : null
    const res = await app.$http.get(`/v2/apps/${param.application}/${type}`, config)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

export default {
  async getItems(param) {
    return await items(param)
  },
  async getItem(param) {
    return await item(param)
  },
  async addItem(param) {
    return await add(param)
  }
}
