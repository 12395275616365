import app from '@/main'
import { saveAs } from 'file-saver'

const items = async (param) => {
  try {
    const limit = `?limit=${param.paging && param.paging.limit ? param.paging.limit : 100}`
    const offset = param.paging && param.paging.offset ? `&offset=${param.paging.offset}` : ''
    const filter =
      'filter' in param &&
      param.filter !== null &&
      param.filter !== undefined &&
      param.filter.length > 0
        ? `&${param.filter}`
        : ''
    const ordering =
      'ordering' in param && param.ordering !== null && param.ordering !== undefined
        ? `&ordering=${param.ordering}`
        : ''
    const roles = 'roles' in param && param.roles?.length ? `&roles=${param.roles}` : ''
    const networks = 'networks' in param && param.networks?.length ? `&${param.networks}` : ''
    const res = await app.$http.get(
      `/v2/organisations/${param.organization}/${param.type}/${limit}${offset}${filter}${ordering}${roles}${networks}`
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const item = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const res = await app.$http.get(`/v2/organisations/${param.organization}/${type}`)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const download = async (param) => {
  try {
    const ordering =
      'ordering' in param && param.ordering !== null && param.ordering !== undefined
        ? `?ordering=${param.ordering}`
        : '?ordering=-date_joined'
    const filter =
      'filter' in param && param.filter !== null && param.filter !== undefined
        ? `&${param.filter}`
        : ''
    const downloadUrl = `/v2/organisations/${param.organization}/users/download/${ordering}${filter}`
    const { data: blob } = await app.$http.get(downloadUrl, {
      responseType: 'blob'
    })
    saveAs(blob, 'simba-users.zip')
    return { success: true }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const add = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const res = await app.$http.post(
      `/v2/organisations/${param.organization}/${type}`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    const errors = e.response?.data?.errors
    if (
      errors?.every(
        (error) => 'title' in error && 'detail' in error && error.title === errors[0].title
      )
    ) {
      return {
        success: false,
        data: {
          title: errors[0].title,
          errors: errors.map((error) => error.detail)
        }
      }
    }
    return {
      success: false,
      data: errors?.map((error) => ('reason' in error ? error.reason : error.detail))
    }
  }
}

const addWithHeader = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''

    let headers = {}
    if (param.wallet?.owner?.type === 'User') {
      headers['txn-account'] = param.wallet.alias
    } else if (param.wallet?.owner?.type === 'Organisation') {
      headers['txn-account'] = param.organization + ':' + param.wallet.alias
    }

    const res = await app.$http.post(
      `/v2/organisations/${param.organization}/${type}`,
      param.payload,
      { headers: headers }
    )
    return { success: true, data: res.data }
  } catch (e) {
    return {
      success: false,
      data: e.response?.data?.errors?.map((error) =>
        'reason' in error ? error.reason : error.detail
      )
    }
  }
}

const remove = async (param) => {
  try {
    const type = param?.type ? `${param.type}/` : ''
    const res = await app.$http.delete(`/v2/organisations/${param.organization}/${type}`)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const removeUser = async (param) => {
  try {
    const res = await app.$http.post(`/v2/organisations/${param.organization}/users/remove/`, {
      user: param.userId
    })
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const update = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const res = await app.$http.put(
      `/v2/organisations/${param.organization}/users/${type}`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return {
      success: false,
      data: e.response?.data?.errors?.map((error) =>
        'reason' in error ? error.reason : error.detail
      )
    }
  }
}

const edit = async (param) => {
  try {
    const res = await app.$http.put(
      `/v2/organisations/${param.organization}/contract_designs/${param.payload.id}/`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const patch = async (param) => {
  try {
    const res = await app.$http.patch(
      `/v2/organisations/${param.organization}/contract_designs/${param.payload.id}/`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const updateOrganization = async (param) => {
  try {
    const res = await app.$http.put(
      `/v2/organisations/${param.organization}/${param.type}/${param.identifier}/`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const account = async (param) => {
  try {
    const res = await app.$http.patch(
      `/v2/organisations/${param.organization}/accounts/${param.id}/`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

export default {
  async getItems(param) {
    return await items(param)
  },
  async getItem(param) {
    return await item(param)
  },
  async addItem(param) {
    return await add(param)
  },
  async addItemWithHeader(param) {
    return await addWithHeader(param)
  },
  async removeItem(param) {
    return await remove(param)
  },
  async downloadUserList(param) {
    return await download(param)
  },
  async deleteUser(param) {
    return await removeUser(param)
  },
  async updateItem(param) {
    return await update(param)
  },
  async editContract(param) {
    return await edit(param)
  },
  async patchContract(param) {
    return await patch(param)
  },
  async addBlockchain(param) {
    return await updateOrganization(param)
  },
  async removeBlockchain(param) {
    return await updateOrganization(param)
  },
  async updateAccount(param) {
    return await account(param)
  }
}
