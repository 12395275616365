import get from 'lodash/get'
import { parser } from '@/components/tools/generate/designer/config/parser'

const initialState = () => ({
  contract: {
    id: null,
    loading: false,
    compiling: false,
    compilationResult: null,
    loaded: false,
    name: 'untitled contract',
    language: 'solidity',
    api: null,
    model: null,
    description: '',
    compilable: null,
    debugging: false,
    noDebugErrors: false
  },
  layout: {
    panel: {
      show: false,
      tab: null
    },
    dialog: {
      show: false,
      component: null,
      data: {
        title: null,
        subtitle: null
      },
      param: null
    },
    main: {
      mode: 'code',
      showQuickAccess: true
    },
    canvas: {
      showNodeList: false,
      rightDrawer: {
        mode: 'list-node'
      },
      floatingWindow: {
        mode: null,
        key: null,
        node: {
          type: null
        },
        position: {
          x: 0,
          y: 0
        }
      },
      newNode: {
        type: null,
        name: null,
        valid: false
      },
      selected: {
        node: null,
        edge: null
      },
      isAddingEdge: false,
      isEditingNode: false,
      showNodeEdgeButton: false,
      isParamWindowOpen: false,
      paramWindowPosition: {
        x: 0,
        y: 0
      },
      paramWindowName: '',
      mainParams: [],
      defaultParams: []
    }
  },
  graph: {
    auth: {},
    name: null,
    lang: 'solidity',
    version: '0.7.0',
    constructorParams: [],
    nodes: [],
    edges: [],
    methods: [],
    meta: null,
    metaLoaded: false
  },
  code: {
    data: '',
    backup: ''
  }
})

const state = initialState

const getters = {
  getNodeNames: (state) => {
    return state.graph.nodes.map((x) => x.label)
  }
}

const actions = {
  setGraph({ commit }, payload) {
    const graph = parser.getGraphByMeta(payload.meta)
    commit('SET', { path: null, key: 'graph', data: graph })
  }
}

const mutations = {
  SET(state, payload) {
    if (payload.path === null) {
      state[payload.key] = payload.data
    } else {
      const ref = get(state, payload.path)
      ref[payload.key] = payload.data
    }
  },
  RESET(state, payload) {
    const initial = initialState()
    const r = get(initial, payload.path)
    const ref = get(state, payload.path)
    Object.keys(ref).forEach((key) => {
      ref[key] = r[key]
    })
  },
  RESET_ALL(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
