import { uuid } from 'vue-uuid'

const parser = {
  getMetaByGraph: (graph) => ({
    contract: {
      auth: graph.auth,
      name: graph.name,
      source: {
        lang: graph.lang,
        version: `^${graph.version}`
      },
      constructor: {
        params: graph.constructorParams
          ? graph.constructorParams.map((param) => ({ name: param.name, type: param.type }))
          : []
      },
      assets: Object.assign(
        {},
        ...graph.nodes
          .filter((node) => node.group === 'asset')
          .map((asset) => ({
            [graph.nodes.find((node) => node.id === asset.id).label]: {
              depends: graph.edges
                .filter((edge) =>
                  graph.nodes.find(
                    (n) => edge.to === n.id && edge.from === asset.id && n.group === 'asset'
                  )
                )
                .map((e) => graph.nodes.find((node) => node.id === e.to).label),
              methods: graph.edges
                .filter((edge) =>
                  graph.nodes.find(
                    (n) => edge.from === n.id && edge.to === asset.id && n.group === 'transaction'
                  )
                )
                .map((e) => graph.nodes.find((node) => node.id === e.from).label)
            }
          }))
      ),
      methods: Object.assign(
        {},
        ...graph.methods.map((method) => ({
          [graph.nodes.find((node) => node.id === method['id']).label]: {
            params: method.params.map((param) => ({ name: param.name, type: param.type }))
          }
        }))
      )
    }
  }),
  getGraphByMeta: (meta) => {
    let assets = []
    for (let [key] of Object.entries(meta.contract.assets)) {
      assets = [...assets, { id: uuid.v4(), label: key, group: 'asset' }]
    }
    let transactions = []
    for (let [key] of Object.entries(meta.contract.methods)) {
      if (!assets.find((a) => a.label === key)) {
        transactions = [...transactions, { id: uuid.v4(), label: key, group: 'transaction' }]
      }
    }
    let nodes = [...assets, ...transactions]
    let methods = nodes.map((node) => ({
      id: node.id,
      params: meta.contract.methods[node.label].params
    }))
    let edges = []
    for (let [key, value] of Object.entries(meta.contract.assets)) {
      value.depends.forEach((asset) => {
        edges = [
          ...edges,
          {
            id: uuid.v4(),
            from: nodes.find((node) => node.label === key).id,
            to: nodes.find((node) => node.label === asset).id
          }
        ]
      })
      value.methods.forEach((transaction) => {
        edges = [
          ...edges,
          {
            id: uuid.v4(),
            from: nodes.find((node) => node.label === transaction).id,
            to: nodes.find((node) => node.label === key).id
          }
        ]
      })
    }

    return {
      auth: 'auth' in meta.contract ? meta.contract.auth : {},
      name: meta.contract.name,
      lang: meta.contract.source.lang,
      version: meta.contract.source.version.substring(1),
      nodes: nodes,
      edges: edges.filter((e) => e.from !== e.to),
      methods: methods
    }
  }
}

export { parser }
