<template>
  <v-banner
    :class="bannerData.bannerClass"
    :style="bannerData.bannerStyle"
    :mobile-breakpoint="bannerMobileBreakpoint"
  >
    <template #icon>
      <v-icon small :class="`on-${bannerData.bannerClass}`"> {{ bannerData.icon }} </v-icon>
    </template>
    <div class="banner-text">
      <div v-if="bannerData.type === 'darkmode'">
        <div v-if="isDarkMode">
          <span class="font-weight-semibold">Dark Mode is in BETA. </span>
          <span>Notice something? </span>
          <a
            href="https://support.simbachain.com/knowledge"
            target="_blank"
            class="on-info-container banner-link"
          >
            <span> Submit Feedback</span>
          </a>
          <span> or </span>
          <span
            class="text-decoration-underline banner-link"
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            Switch to Light Mode
          </span>
        </div>
        <div v-if="!isDarkMode">
          <span>You've switched to Light Mode. </span>
          <span
            class="text-decoration-underline banner-link"
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            Switch back to Dark Mode (BETA)
          </span>
        </div>
      </div>
      <span v-else>
        {{ formattedMessage }}
        <span v-if="bannerData.alertCount > 1"
          >(and
          <span class="text-decoration-underline"
            >{{ bannerData.alertCount - 1 }} other{{ bannerData.alertCount > 2 ? 's' : '' }}</span
          >)</span
        >
      </span>
    </div>

    <template #actions="{ dismiss }">
      <v-btn
        v-if="bannerData.dismissible"
        icon
        style="position: absolute; top: 50%; right: 20px; transform: translate(0%, -50%)"
        @click="dismissBanner(bannerData, dismiss)"
      >
        <v-icon :class="`on-${bannerData.bannerClass}`">fa-regular fa-times</v-icon>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: 'BlocksBanner',
  props: {
    bannerData: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    formattedMessage() {
      let message = this.bannerData.message

      if (this.bannerData.type === 'alert') {
        const startDate = this.locale
          ? this.bannerData.startDate.toLocaleString(this.locale)
          : this.bannerData.startDate.toLocaleString()
        const endDate = this.locale
          ? this.bannerData.endDate.toLocaleString(this.locale)
          : this.bannerData.endDate.toLocaleString()

        message = `${message} from ${startDate} to ${endDate}`
      }
      return message
    },
    smAndUp() {
      return this.$vuetify.breakpoint.smAndUp
    },
    bannerMobileBreakpoint() {
      return this.$vuetify.breakpoint.thresholds.sm
    },
    isDarkMode() {
      return this.$vuetify.theme.dark
    }
  },
  methods: {
    dismissBanner(banner) {
      this.$emit('dismiss-banner', banner)
    }
  }
}
</script>

<style lang="scss">
.banner {
  min-height: 41px;
  border-bottom: thin solid var(--v-background-base);
}

.banner-link {
  text-decoration: underline;
  cursor: pointer;
}

.banner-link:hover {
  text-decoration: none;
}

.banner-link:active {
  text-decoration: underline;
}

.v-application--is-ltr .v-banner--is-mobile .v-banner__wrapper,
.v-application--is-ltr .v-banner__wrapper {
  padding: 10px 20px 10px 20px;
}
.v-banner__wrapper {
  border-bottom: none !important;

  .v-banner__content {
    .v-banner__text {
      display: flex;
      .banner-text {
        min-height: 20px;
        top: 50%;
        position: absolute;
        transform: translate(16px, -50%);
        padding-left: 28px;
        padding-right: 40px;
      }
    }
    .v-banner__icon {
      display: flex;
      position: absolute;
      margin-right: 20px;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
}
.v-banner--is-mobile {
  height: 82px;
}
</style>
