import meService from '@/services/me'

const state = {
  notificationList: [],
  dismissedBanners: {}
}

const actions = {
  clear({ commit }) {
    commit('clear')
  },
  async getNotifications({ commit }) {
    const { success, data } = await meService.getItems({ type: 'system_notifications' })

    if (success && Array.isArray(data?.results)) {
      commit(
        'setNotifications',
        data.results.map(
          ({ id, message, notification_start, notification_end, dismissible, disabled }) => ({
            id,
            message,
            notificationStart: notification_start,
            notificationEnd: notification_end,
            dismissible,
            disabled
          })
        )
      )
    }
  },
  dismissBanner({ commit }, bannerId) {
    commit('dismissBanner', `alertBannerClosed_${bannerId}`)
  }
}
const mutations = {
  clear(state) {
    state.notificationList = []
  },
  setNotifications(state, notificationList) {
    state.notificationList = notificationList
  },
  dismissBanner(state, bannerId) {
    state.dismissedBanners[bannerId] = true
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
