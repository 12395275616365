<template>
  <component :is="comName" v-if="hasCom"></component>
</template>

<script>
export default {
  components: {
    HomeNavDrawer: () => import('./home/HomeNavDrawer'),
    OrgNavDrawer: () => import('./org/OrgNavDrawer'),
    AppNavDrawer: () => import('./app/AppNavDrawer')
  },
  computed: {
    hasCom() {
      return this.$route.meta.drawer !== null
    },
    comName() {
      return this.$route.meta.drawer
    }
  }
}
</script>
