import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import Deferred from 'promise-deferred'

const JSONbigint = require('json-bigint')({ storeAsString: true, constructorAction: 'ignore' })
let instance = null

const axiosRequestInterceptor = (config) => {
  return config
}

const axiosResponseInterceptorSuccess = (response) => {
  if (
    response.headers['content-type'] === 'application/json' &&
    'data' in response &&
    typeof response.data === 'string'
  ) {
    try {
      response.data = JSONbigint.parse(response.data)
      return response
    } catch (error) {
      console.error(error)
    }
  }
  return response
}

const axiosResponseInterceptorFailure = (error) => {
  console.log(error)
  if (error.response.status === 401) {
    if (error.response.data.errors[0].code === '1401') {
      if (instance) {
        instance.isAuthenticated = false
        window.location.replace(instance.getLoginLink())
      }
    }
  }
  return Promise.reject(error)
}

export const AuthGatewayPlugin = Vue.extend({
  data() {
    return {
      loading: true,
      base_url: process.env.VUE_APP_BASE_URL,
      isAuthentloading: false,
      error: null,
      isAuthenticated: false,
      loginLink: '/login/',
      logoutLink: '/logout/',
      userMe: '/user/whoami/',
      userOrgs: '/user/organisations/',
      userData: null,
      waitingForLoading: [],
      waitingForAuth: [],
      logoutListeners: [],
      logout_status: null,
      logout_status_msg: null
    }
  },
  async created() {
    // Gets the URL from the server for the API if deployed. Ignored locally.
    let request = new XMLHttpRequest()
    try {
      let url = new URL(window.location.href)
      url.pathname = 'env'
      await request.open('GET', url.toString(), false)
      request.send(null)
      if (request.status >= 200 && request.status < 300) {
        if (!request.responseText.startsWith('<')) {
          this.base_url = request.responseText.trim()
        }
      }
    } catch (e) {
      console.log('Error getting env, using default')
    }

    axios.defaults.baseURL = this.base_url
    axios.defaults.withCredentials = true
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
    axios.interceptors.request.use(axiosRequestInterceptor)
    axios.interceptors.response.use(
      axiosResponseInterceptorSuccess,
      axiosResponseInterceptorFailure
    )

    await this.checkAuthentication()

    this.waitForLogout()
      .then(() => {
        console.log('Logged Out')
      })
      .catch((e) => {
        console.log('Log out error', e)
      })
  },
  methods: {
    getLoginLink() {
      let url = new URL(this.base_url + this.loginLink)
      url.searchParams.append('next', window.location.href)
      return url.toString()
    },
    logout(redirect_url) {
      let url = new URL(this.base_url + this.logoutLink)
      url.searchParams.append(
        'next',
        redirect_url || instance.getLoginLink() || 'https://simbachain.com/blocks'
      )
      store.commit('user/RESET', { path: 'me' })
      sessionStorage.clear()
      window.location.href = url.toString()
    },
    async checkSession() {
      const uninterceptedAxiosInstance = axios.create()
      uninterceptedAxiosInstance.defaults = axios.defaults
      await uninterceptedAxiosInstance.get(this.base_url + this.userMe, {
        withCredentials: true
      })
    },
    async checkAuthentication() {
      // We want to skip interceptors here to prevent loops on 401 errors
      const uninterceptedAxiosInstance = axios.create()
      uninterceptedAxiosInstance.defaults = axios.defaults

      try {
        let response = await uninterceptedAxiosInstance.get(this.base_url + this.userMe, {
          withCredentials: true
        })
        let orgs = await uninterceptedAxiosInstance.get(this.base_url + this.userOrgs, {
          withCredentials: true
        })
        this.tAndCApproved = true
        this.userData = { ...response.data, organisations: orgs.data.results }
        store.commit('user/SET', { path: 'me', key: 'data', data: this.userData })

        Vue.prototype.$datadog.setUser(response.data)

        this.isAuthenticated = true

        this.waitingForAuth.forEach((fn) => {
          fn(this.userData)
        })

        this.loading = false
      } catch (error) {
        console.error(error)
        this.isAuthenticated = false
        this.error = error

        if (error.response) {
          if (error.response.status === 401) {
            window.location = this.getLoginLink()
          }
        }

        this.loading = false
      }

      this.waitingForLoading.forEach((fn) => {
        fn(this.isAuthenticated)
      })

      return this.isAuthenticated
    },
    async me() {
      if (this.isAuthenticated) {
        const uninterceptedAxiosInstance = axios.create()
        uninterceptedAxiosInstance.defaults = axios.defaults
        let response = await uninterceptedAxiosInstance.get(this.base_url + this.userMe, {
          withCredentials: true
        })
        this.userData = response.data
        store.commit('user/SET', { path: 'me', key: 'data', data: this.userData })

        return Promise.resolve(this.userData)
      }
      // If not authenticated, return a promise that'll get resolved when we get authenticated.
      let p = new Deferred()
      this.waitingForAuth.push(p)
      return p.promise
    },
    waitForLogout() {
      if (this.logout_status !== null) {
        store.commit('user/RESET', { path: 'me' })

        if (this.logout_status) {
          return Promise.resolve(this.logout_status_msg)
        } else {
          return Promise.reject(this.logout_status_msg)
        }
      }
      // If not logged out, return a promise that'll get resolved when we get logged out.
      let p = new Deferred()
      this.logoutListeners.push(p)
      return p.promise
    },
    waitForAuth(fn) {
      if (this.isAuthenticated) {
        fn(this.userData)
      }
      this.waitingForAuth.push(fn)
    },
    waitForLoaded(fn) {
      if (!this.loading) {
        fn(this.isAuthenticated)
      }
      this.waitingForLoading.push(fn)
    },
    notifyLogoutListeners() {
      this.logoutListeners.forEach((listener) => {
        if (this.logout_status !== null) {
          if (this.logout_status) {
            listener.resolve(this.logout_status_msg)
          } else {
            listener.reject(this.logout_status_msg)
          }
        } else {
          //nothing to notify
        }
      })
    }
  }
})

/**
 * Gets an instance of the AuthGatewayPlugin
 * @returns {AuthGatewayPlugin}
 */
export const getInstance = () => {
  if (!instance) {
    instance = new AuthGatewayPlugin()
  }
  // console.log(instance)
  return instance
}

export const auth = {
  install(Vue) {
    Vue.prototype.$auth = getInstance()
  }
}
