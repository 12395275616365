import app from '@/main'

const me = async () => {
  try {
    const res = await app.$auth.me()
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const permissions = async (param) => {
  const queryParams = param?.organization ? `?organisation=${param.organization}` : ''
  try {
    const res = await app.$http.get(`/user/permissions/${queryParams}`)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const items = async (param) => {
  try {
    const limit = `?limit=${param.paging && param.paging.limit ? param.paging.limit : 100}`
    const offset = param.paging && param.paging.offset ? `&offset=${param.paging.offset}` : ''
    const filter =
      'filter' in param && typeof param.filter === 'string' && param.filter?.length > 0
        ? `&${param.filter}`
        : ''
    const networks =
      'networks' in param && typeof param.networks === 'string' && param.networks?.length > 0
        ? `&${param.networks}`
        : ''
    const res = await app.$http.get(`/user/${param.type}/${limit}${offset}${filter}${networks}`)
    return { success: true, data: res.data }
  } catch (e) {
    return {
      success: false,
      data: e.response?.data?.errors?.map((error) => error.detail),
      statusText: e.response?.statusText
    }
  }
}

const add = async (param) => {
  try {
    const type = 'type' in param && param.type ? `${param.type}/` : ''
    const res = await app.$http.post(`/user/${type}`, param.payload)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

const remove = async (param) => {
  const type = 'type' in param && param.type ? `${param.type}/` : ''
  try {
    const res = await app.$http.delete(`/user/${type}`)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors }
  }
}

const update = async (param) => {
  try {
    const res = await app.$http.put(`/user/${param.type}/`, param.payload)
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

export default {
  async getMe() {
    return await me()
  },
  async getPermissions(param) {
    return await permissions(param)
  },
  async getItems(param) {
    return await items(param)
  },
  async addItem(param) {
    return await add(param)
  },
  async removeItem(param) {
    return await remove(param)
  },
  async updateItem(param) {
    return await update(param)
  }
}
