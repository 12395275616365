var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.76515 3.11719L1.56763 3.71606V14.2269L2.76515 14.8223L2.77235 14.8136V3.12543L2.76515 3.11719Z",
          fill: "#8C3123",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.17431 13.2967L2.76538 14.8223V3.11719L9.17431 4.6095V13.2967Z",
          fill: "#E05243",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.28125 10.9084L8.99971 11.2545L9.01678 11.2151L9.03206 6.75749L8.99971 6.72266L6.28125 7.06373V10.9084Z",
          fill: "#8C3123",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.99976 13.3138L15.2342 14.8255L15.244 14.8099L15.2438 3.12764L15.234 3.11719L8.99976 4.62663V13.3138Z",
          fill: "#8C3123",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.719 10.9084L8.99976 11.2545V6.72266L11.719 7.06373V10.9084Z",
          fill: "#E05243",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.7189 5.20431L8.99971 5.6999L6.28125 5.20431L8.99629 4.49268L11.7189 5.20431Z",
          fill: "#5E1F18",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.7189 12.7625L8.99971 12.2637L6.28125 12.7625L8.9964 13.5204L11.7189 12.7625Z",
          fill: "#F2B0A9",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.28125 5.20438L8.99971 4.53164L9.02172 4.52485V0.0181742L8.99971 0L6.28125 1.35946V5.20438Z",
          fill: "#8C3123",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.719 5.20438L8.99976 4.53164V0L11.719 1.35946V5.20438Z",
          fill: "#E05243",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.99976 17.9666L6.28101 16.6076V12.7627L8.99976 13.4352L9.03977 13.4807L9.02891 17.8881L8.99976 17.9666Z",
          fill: "#8C3123",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.99976 17.9667L11.7187 16.6077V12.7629L8.99976 13.4354V17.9667ZM15.2342 3.11719L16.4322 3.71606V14.2269L15.2342 14.8255V3.11719Z",
          fill: "#E05243",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }