var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "logos",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "10.3",
          y: "16.31",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-10.68 20.81) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "14.07",
          y: "8.41",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-1.41 19.71) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "3.16",
          y: "13.75",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-11.67 12.42) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.93",
          y: "5.85",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-2.4 11.33) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "2.54",
          y: "10.32",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-7.18 10.96) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.79",
          y: "3.18",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(1.2 9.97) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "10.46",
          y: "14.1",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-6.08 20.26) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "14.71",
          y: "6.96",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(2.3 19.27) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "8.42",
          y: "8.21",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-1.93 15.07) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "5.86",
          y: "15.35",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-10.31 16.06) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "16.32",
          y: "11.98",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-.83 24.35) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "13.76",
          y: "19.12",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-9.21 25.34) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "8.22",
          y: "10.47",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-6.6 15.61) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "15.38",
          y: "14.73",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-5.6 24.01) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "11.99",
          y: "2.58",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(2.67 14.51) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "19.15",
          y: "6.84",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(3.67 22.91) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "10.3",
          y: "16.31",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-10.68 20.81) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "14.07",
          y: "8.41",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-1.41 19.71) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "3.16",
          y: "13.75",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-11.67 12.42) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.93",
          y: "5.85",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-2.4 11.33) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "2.54",
          y: "10.32",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-7.18 10.96) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.79",
          y: "3.18",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(1.2 9.97) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "10.46",
          y: "14.1",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-6.08 20.26) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "14.71",
          y: "6.96",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(2.3 19.27) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "8.42",
          y: "8.21",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-1.93 15.07) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "5.86",
          y: "15.35",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-10.31 16.06) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "16.32",
          y: "11.98",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-.83 24.35) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "13.76",
          y: "19.12",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-9.21 25.34) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "8.22",
          y: "10.47",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(-6.6 15.61) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "15.38",
          y: "14.73",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(-5.6 24.01) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "11.99",
          y: "2.58",
          width: "1.69",
          height: "5.12",
          rx: ".85",
          ry: ".85",
          transform: "translate(2.67 14.51) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: {
          x: "19.15",
          y: "6.84",
          width: "1.69",
          height: "3.43",
          rx: ".85",
          ry: ".85",
          transform: "translate(3.67 22.91) rotate(-64.5)",
        },
      }),
      _c("rect", {
        attrs: { fill: "none", x: "0", width: "24", height: "24" },
      }),
      _c("rect", {
        attrs: { fill: "none", x: "0", width: "24", height: "24" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }