import { render, staticRenderFns } from "./IconQuorum.vue?vue&type=template&id=f819dd06"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/SIMBAChain/simba-enterprise-platform/blocks-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f819dd06')) {
      api.createRecord('f819dd06', component.options)
    } else {
      api.reload('f819dd06', component.options)
    }
    module.hot.accept("./IconQuorum.vue?vue&type=template&id=f819dd06", function () {
      api.rerender('f819dd06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/IconQuorum.vue"
export default component.exports