var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 18 16" },
      attrs: {
        id: "Layer_1",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 18 16",
        "xml:space": "preserve",
        fill: "currentColor",
      },
    },
    [
      _c("g", [
        _c("g", [
          _c("polygon", { attrs: { points: "8.07,4 8.07,0 12.1,4 \t\t" } }),
          _c("path", {
            attrs: {
              d: "M11.21,15.19l-0.57,0.18c-0.56,0.17-1.16,0-1.53-0.44c-0.19-0.23-0.35-0.46-0.5-0.7l-0.09-0.16\n\t\t\tc-0.15-0.26-0.26-0.52-0.36-0.78c-0.2-0.54-0.05-1.14,0.38-1.54l0.44-0.4c0-0.02,0-0.03,0-0.05c0-0.02,0-0.03,0-0.05l-0.44-0.4\n\t\t\tc-0.44-0.4-0.58-1-0.38-1.54c0.1-0.26,0.22-0.52,0.35-0.77l0.1-0.16c0.16-0.26,0.32-0.49,0.5-0.71c0.37-0.45,0.97-0.62,1.53-0.44\n\t\t\tl0.57,0.18c0.03-0.02,0.06-0.04,0.09-0.05l0.13-0.58c0.09-0.4,0.34-0.71,0.67-0.91V5H8.07C7.51,5,7.06,4.55,7.06,4V0H2.02\n\t\t\tC0.9,0,0,0.9,0,2v12c0,1.1,0.9,2,2.02,2h8.07c0.49,0,0.93-0.18,1.28-0.47l-0.06-0.28C11.27,15.23,11.24,15.21,11.21,15.19z",
            },
          }),
        ]),
        _c("path", {
          attrs: {
            d: "M17.06,11.77c0.02-0.15,0.03-0.31,0.03-0.47c0-0.16-0.01-0.31-0.03-0.47l0.8-0.72c0.13-0.12,0.18-0.29,0.12-0.45\n\t\tc-0.08-0.22-0.18-0.43-0.29-0.63L17.6,8.89c-0.12-0.2-0.26-0.39-0.41-0.57c-0.11-0.13-0.29-0.18-0.45-0.12l-1.03,0.33\n\t\tc-0.25-0.19-0.54-0.35-0.81-0.47L14.66,7c-0.04-0.17-0.17-0.3-0.34-0.33c-0.26-0.04-0.52-0.06-0.79-0.06\n\t\tc-0.27,0-0.53,0.02-0.79,0.06C12.58,6.7,12.45,6.84,12.42,7l-0.23,1.05c-0.03,0.01-0.06,0.03-0.09,0.04\n\t\tc-0.26,0.11-0.51,0.25-0.73,0.43l-1.03-0.33C10.18,8.14,10,8.18,9.89,8.32C9.74,8.5,9.6,8.69,9.48,8.89L9.39,9.04\n\t\tC9.28,9.24,9.18,9.45,9.1,9.67C9.04,9.83,9.09,10,9.22,10.12l0.8,0.72c-0.02,0.15-0.03,0.31-0.03,0.47c0,0.16,0.01,0.31,0.03,0.47\n\t\tl-0.8,0.72c-0.13,0.12-0.18,0.29-0.12,0.45c0.08,0.22,0.18,0.43,0.29,0.63l0.09,0.15c0.12,0.2,0.26,0.39,0.41,0.57\n\t\tc0.11,0.13,0.29,0.18,0.45,0.12l1.03-0.33c0.2,0.15,0.43,0.27,0.66,0.38c0.05,0.02,0.1,0.06,0.15,0.09l0.23,1.05\n\t\tc0.04,0.17,0.17,0.28,0.34,0.33c0.26,0.04,0.52,0.06,0.77,0.06c0.28,0,0.55-0.02,0.8-0.06c0.17-0.04,0.3-0.16,0.34-0.33l0.23-1.05\n\t\tc0.28-0.12,0.57-0.28,0.81-0.47l1.03,0.33c0.16,0.05,0.34,0.01,0.45-0.12c0.15-0.18,0.29-0.37,0.41-0.57l0.09-0.15\n\t\tc0.11-0.2,0.21-0.41,0.29-0.63c0.06-0.16,0.01-0.34-0.12-0.45L17.06,11.77z M12.1,10.96c0.15-0.64,0.74-1.14,1.44-1.14\n\t\tc0.82,0,1.48,0.67,1.48,1.47c0,0.83-0.66,1.48-1.48,1.48c-0.7,0-1.29-0.49-1.44-1.15c-0.02-0.11-0.04-0.22-0.04-0.34\n\t\tC12.06,11.18,12.07,11.07,12.1,10.96z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }