var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M140.5 0C62.9 0 0 62.9 0 140.5C0 218.1 62.9 281 140.5 281C218.1 281 281 218.1 281 140.5C281 62.9 218.1 0 140.5 0Z",
          fill: "#2C56DD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M219.952 155.863C212.56 155.91 206.596 161.865 206.632 169.184C206.632 169.196 206.632 169.196 206.632 169.208C206.596 176.515 212.56 182.47 219.952 182.505C227.345 182.458 233.297 176.504 233.273 169.208C233.333 161.9 227.381 155.922 219.988 155.863C219.976 155.863 219.964 155.863 219.952 155.863Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M216.204 140.603C222.57 136.928 224.766 128.779 221.09 122.415C217.414 116.051 209.262 113.855 202.897 117.531C196.554 121.182 194.358 129.272 197.964 135.636L198.046 135.754C201.722 142.106 209.838 144.278 216.192 140.603C216.192 140.615 216.204 140.615 216.204 140.603Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M78.5672 117.603C72.1978 113.915 64.042 116.076 60.352 122.442C56.6619 128.808 58.8242 136.959 65.1937 140.646C71.5632 144.334 79.7189 142.173 83.409 135.808V135.761C87.0755 129.407 84.9132 121.279 78.5672 117.603Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M100.395 113.926C107.761 113.914 113.726 107.935 113.714 100.581C113.714 98.2787 113.114 96.0232 111.973 94.0262C108.361 87.6476 100.242 85.3922 93.8418 89.0102C93.83 89.0102 93.83 89.022 93.8182 89.022L93.5947 89.1512C87.2648 92.9103 85.1823 101.086 88.9473 107.406C91.3475 111.435 95.689 113.914 100.395 113.926Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M140.385 76.4199C133.032 76.4199 127.064 82.3873 127.064 89.7409C127.064 97.0945 133.032 103.062 140.385 103.062C147.739 103.062 153.706 97.0945 153.706 89.7409C153.706 82.3873 147.739 76.4199 140.385 76.4199Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M97.8885 155.873C90.535 155.873 84.5675 161.84 84.5675 169.194C84.5675 176.547 90.535 182.515 97.8885 182.515C105.242 182.515 111.21 176.547 111.21 169.194C111.21 161.84 105.242 155.873 97.8885 155.873Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M162.656 119.517C155.303 119.517 149.335 125.484 149.335 132.838C149.335 140.191 155.303 146.159 162.656 146.159C170.01 146.159 175.977 140.191 175.977 132.838C175.977 125.484 170.01 119.517 162.656 119.517Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M173.788 112.625L173.96 112.721C180.823 116.132 189.197 113.432 192.672 106.696C195.876 100.479 193.801 92.887 187.846 89.0792C181.007 85.6208 172.609 88.2477 169.085 94.9596C165.819 101.165 167.845 108.781 173.788 112.625Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.3065 155.863C53.957 155.745 47.8796 161.59 47.7264 168.942C47.7264 169.024 47.7264 169.13 47.7264 169.213C47.75 176.577 53.7215 182.527 61.0828 182.504C68.444 182.48 74.392 176.506 74.3684 169.142C74.3449 161.908 68.5383 156.005 61.3065 155.863Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M182.428 155.833C175.074 155.833 169.107 161.801 169.107 169.154C169.107 176.508 175.074 182.475 182.428 182.475C189.781 182.475 195.749 176.508 195.749 169.154C195.749 161.801 189.781 155.833 182.428 155.833Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M126.078 143.915C132.201 139.855 133.866 131.594 129.808 125.469C126.113 119.895 118.852 117.935 112.858 120.88L112.776 120.927C106.196 124.19 103.51 132.181 106.77 138.764C110.031 145.347 118.019 148.034 124.6 144.772C125.116 144.525 125.608 144.232 126.078 143.915Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }