import { getInstance } from './authWrapper'

export const authGuard = async (to, from, next) => {
  const auth = getInstance()
  auth.waitForLoaded((authed) => {
    if (authed) {
      next()
    } else {
      window.location.replace(auth.getLoginLink())
    }
  })
}
