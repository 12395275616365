<template>
  <component :is="comName" v-if="hasCom"></component>
</template>

<script>
export default {
  components: {
    AppNavBar: () => import('./app/AppNavBar'),
    HomeNavBar: () => import('./home/HomeNavBar')
  },
  computed: {
    hasCom() {
      return this.$route.meta.header !== null
    },
    comName() {
      return this.$route.meta.header
    }
  }
}
</script>
