import get from 'lodash/get'

const initialState = () => ({
  organization: {
    all: false,
    recent: false
  },
  application: {
    all: false,
    recent: false,
    contracts: false,
    transactions: false,
    subscriptions: false
  },
  contract: {
    all: false,
    recent: false
  },
  subscriptions: {
    all: false
  },
  accounts: {
    all: false
  },
  nodes: {
    all: false
  },
  secrets: {
    all: false
  },
  error: {
    detail: null
  }
})

const state = initialState

const getters = {}

const actions = {}

const mutations = {
  SET(state, payload) {
    if (payload.path === null) {
      state[payload.key] = payload.data
    } else {
      const ref = get(state, payload.path)
      ref[payload.key] = payload.data
    }
  },
  RESET(state, payload) {
    const initial = initialState()
    const r = get(initial, payload.path)
    const ref = get(state, payload.path)
    Object.keys(ref).forEach((key) => {
      ref[key] = r[key]
    })
  },
  RESET_ALL(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
