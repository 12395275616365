import Vue from 'vue'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

let instance = null

export const DataDogPlugin = Vue.extend({
  data() {
    return {
      datadogRum: false,
      datadogLogs: false
    }
  },
  created() {
    if (this.isRUMEnabled()) {
      // regex patterns to identify known bot instances:
      const botPattern =
        '(googlebot|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'

      const regex = new RegExp(botPattern, 'i')

      // define var conditionalSampleRate as 0 if the userAgent matches a pattern in botPatterns
      // otherwise, define conditionalSampleRate as 100
      const conditionalSampleRate = regex.test(navigator.userAgent)
        ? 0
        : process.env.VUE_APP_RUM_SAMPLE_RATE || 100
      const sessionReplaySampleRate = regex.test(navigator.userAgent)
        ? 0
        : process.env.VUE_APP_RUM_SESSION_REPLAY_SAMPLE_RATE || 20

      datadogLogs.init({
        clientToken: 'pub5ec3bd98032be2753efe9a36d40a746e',
        site: 'datadoghq.com',
        service: 'blocks-ui',
        env: window.location.hostname,
        version:
          process.env.VUE_APP_VERSION || require('../../package.json').version || '0.0.1-dev',
        forwardErrorsToLogs: true,
        sessionSampleRate: conditionalSampleRate
      })

      this.datadogLogs = true

      datadogRum.init({
        applicationId: 'da0088af-c646-4de5-a223-bbe679885662',
        clientToken: 'pub5ec3bd98032be2753efe9a36d40a746e',
        site: 'datadoghq.com',
        service: 'blocks-ui',
        env: window.location.hostname,
        // Specify a version number to identify the deployed version of your application in Datadog
        version:
          process.env.VUE_APP_VERSION || require('../../package.json').version || '0.0.1-dev',
        sessionSampleRate: conditionalSampleRate,
        sessionReplaySampleRate: sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['clickmap'],
        allowedTracingUrls: [
          {
            match: /https:\/\/.*\.simbachain\.com/,
            propagatorTypes: ['tracecontext', 'x-datadog-*']
          }
        ]
      })

      datadogRum.startSessionReplayRecording()

      this.datadogRum = true
    }
  },
  methods: {
    setUser(userData) {
      try {
        if (this.datadogRum) {
          datadogRum.setUser({
            id: userData.email,
            email: userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name
          })
        }
      } catch (e) {
        // Don't put the user in a login loop if we fail.
        console.error("Couldn't set details on RUM.")
      }
    },
    isRUMEnabled() {
      const cookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('EnableRUM='))
        ?.split('=')[1]

      return cookie === '1' || cookie === 1
    }
  }
})

/**
 * Gets an instance of the DataDogPlugin
 * @returns {DataDogPlugin}
 */
export const getInstance = () => {
  if (!instance) {
    instance = new DataDogPlugin()
  }
  return instance
}

export const datadog = {
  install(Vue) {
    Vue.prototype.$datadog = getInstance()
  }
}
