import VueRouter from 'vue-router'

const updateParams = (route, router, parameters) => {
  let value
  let paramName
  let query = {
    ...route.query
  }

  for (const paramIndex in parameters) {
    value = parameters[paramIndex].value
    paramName = parameters[paramIndex].paramName
    if (!value) {
      delete query[paramName]
    } else {
      const doesNotExitOrDifferentValue = !query[paramName] || query[paramName] !== value
      if (doesNotExitOrDifferentValue) {
        query = {
          ...query,
          [paramName]: value
        }
      }
    }
  }

  router
    .push({
      query
    })
    .catch((err) => {
      if (!VueRouter.isNavigationFailure(err)) {
        throw err
      }
    })
}

export default {
  updateParams(route, router, parameters) {
    return updateParams(route, router, parameters)
  }
}
