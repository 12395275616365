var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("NavBar"),
      _c("v-main", [
        _c(
          "div",
          { staticClass: "d-flex flex-column full-height" },
          [
            _c("BannerContainer", { attrs: { config: _vm.configuration } }),
            _c(
              "div",
              { staticStyle: { display: "flex", position: "relative" } },
              [
                _c("div", [_c("NavDrawer")], 1),
                _c(
                  "div",
                  { staticStyle: { "flex-grow": "1", width: "70%" } },
                  [_c("router-view")],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.authLoading ? _c("BlocksLoader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }