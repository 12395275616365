import { render, staticRenderFns } from "./BannerContainer.vue?vue&type=template&id=157b600e"
import script from "./BannerContainer.vue?vue&type=script&lang=js"
export * from "./BannerContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/SIMBAChain/simba-enterprise-platform/blocks-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('157b600e')) {
      api.createRecord('157b600e', component.options)
    } else {
      api.reload('157b600e', component.options)
    }
    module.hot.accept("./BannerContainer.vue?vue&type=template&id=157b600e", function () {
      api.rerender('157b600e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/shared/banner/container/BannerContainer.vue"
export default component.exports