<template>
  <div
    class="banner-container"
    :style="{ height: banners.length > 0 ? `${banners.length * bannerHeightFactor * 41}px` : '' }"
  >
    <div style="position: fixed; z-index: 5; width: 100%">
      <BlocksBanner
        v-for="(banner, index) in banners"
        :key="index"
        :banner-data="banner"
        :locale="alertLocale"
        class="banner d-block"
        @dismiss-banner="dismissBanner(banner)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BlocksBanner from '@/components/layout/shared/banner/blocksBanner/BlocksBanner'
export default {
  name: 'BannerContainer',
  components: {
    BlocksBanner
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    displayDarkModeBanner: false,
    alerts: [],
    currentAlertIndex: 0,
    bannerType: '',
    sandboxData: {},
    darkModeData: {},
    alertData: {},
    banners: []
  }),
  computed: {
    ...mapState('notifications', ['notificationList', 'dismissedBanners']),
    isDarkModeBannerDismissed() {
      return this.dismissedBanners['alertBannerClosed_darkmodeBannerClosed']
    },
    isDarkMode() {
      return this.$vuetify.theme.dark
    },
    isSandboxMode() {
      const overrideSandBoxModeFlag =
        typeof this.config !== 'undefined' &&
        typeof this.config.VUE_APP_SANDBOX_MODE !== 'undefined'

      return overrideSandBoxModeFlag
        ? this.config.VUE_APP_SANDBOX_MODE === 'TRUE'
        : process.env.VUE_APP_SANDBOX_MODE === 'TRUE'
    },
    authLoading() {
      return this.$auth.loading
    },
    bannerHeightFactor() {
      return this.$vuetify.breakpoint.smAndDown ? 2 : 1
    },
    alertLocale() {
      const overrideAlertLocale =
        typeof config !== 'undefined' && typeof this.config.VUE_APP_ALERT_LOCALE !== 'undefined'

      return overrideAlertLocale
        ? this.config.VUE_APP_ALERT_LOCALE
        : process.env.VUE_APP_ALERT_LOCALE
    },
    filteredAlerts() {
      return this.alerts.filter((alert) => !this.dismissedBanners[alert.id])
    },
    currentAlert() {
      return this.filteredAlerts[this.currentAlertIndex]
    },
    remainingAlertsCount() {
      return this.filteredAlerts.length
    }
  },
  watch: {
    isDarkMode(newVal) {
      this.$store.commit('theme/switchDarkMode', newVal)
      if (newVal && !this.isDarkModeBannerDismissed) {
        this.displayDarkModeBanner = true
        this.populateBanners()
      }
    }
  },
  created() {
    if (this.isDarkMode && !this.isDarkModeBannerDismissed) {
      this.displayDarkModeBanner = true
    }

    setTimeout(async () => {
      await this.getNotifications()
      this.alerts = this.notificationList
      this.populateBanners()
    }, 1000)
  },
  methods: {
    dismissBanner(banner) {
      if (banner.type === 'darkmode') {
        this.displayDarkModeBanner = false
        this.$store.dispatch('notifications/dismissBanner', 'darkmodeBannerClosed')
        this.populateBanners()
      } else {
        const alertIndex = this.filteredAlerts.findIndex((alert) => alert.id === banner.id)
        if (alertIndex !== -1) {
          this.$store.dispatch('notifications/dismissBanner', banner.id)
          this.populateBanners()
        }
      }
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d)
    },
    transformAlertData() {
      if (this.currentAlert && Object.keys(this.currentAlert).length !== 0) {
        this.banners = [
          {
            type: 'alert',
            startDate: new Date(this.currentAlert.notificationStart),
            endDate: new Date(this.currentAlert.notificationEnd),
            message: this.currentAlert.message,
            icon: 'fa-regular fa-triangle-exclamation',
            bannerClass: 'warning-container',
            dismissible: this.currentAlert.dismissible,
            id: this.currentAlert.id,
            alertCount: this.remainingAlertsCount
          }
        ]
      } else {
        this.banners = []
      }
    },
    populateBanners() {
      this.alerts = this.notificationList.filter(
        (alert) => !this.dismissedBanners[`alertBannerClosed_${alert.id}`]
      )

      this.alerts = this.sortAlerts(this.alerts)
      this.banners = this.currentAlert
      this.transformAlertData()

      if (this.displayDarkModeBanner) {
        const darkmodeBanner = {
          type: 'darkmode',
          message: 'You are using a sandbox instance of Simba Blocks.',
          icon: this.isDarkMode ? 'fa-solid fa-moon-stars' : 'fa-solid fa-sun-bright',
          bannerClass: 'info-container',
          dismissible: true
        }

        this.banners = [darkmodeBanner, ...this.banners]
      }

      if (this.isSandboxMode && !this.authLoading) {
        const sandboxBanner = {
          type: 'sandbox',
          message: 'You are using a sandbox instance of Simba Blocks.',
          icon: 'fa-solid fa-circle-info',
          bannerClass: 'info-container',
          dismissible: false
        }

        this.banners = [sandboxBanner, ...this.banners]
      }
    },
    sortAlerts(alerts) {
      return alerts.sort((a, b) => {
        const priorityA = a.metadata?.priority
        const priorityB = b.metadata?.priority

        if (priorityA !== undefined && priorityB !== undefined) {
          return priorityA - priorityB
        }

        if (priorityA !== undefined) return -1
        if (priorityB !== undefined) return 1
        return 0
      })
    },
    ...mapActions('notifications', ['getNotifications', 'clear'])
  }
}
</script>
