import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import services from './services'
import { datadog } from '@/plugins/datadog'
import { auth } from '@/plugins/auth'
import vuetify from './plugins/vuetify'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(datadog)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(auth)

const app = new Vue({
  router,
  store,
  vuetify,
  provide: services,
  render: (h) => h(App)
}).$mount('#app')

export default app
