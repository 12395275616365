import meService from './me'
import organizationService from './organization'
import applicationService from './application'
import queryParamService from './queryParam'

export default {
  meService,
  organizationService,
  applicationService,
  queryParamService
}
