import get from 'lodash/get'

const initialState = () => ({
  gql: {
    loading: null,
    id: null
  },
  layout: {
    showResult: false
  },
  graph: {
    nodes: [],
    edges: [],
    fields: [],
    nodeFields: [],
    fieldNames: [],
    adjacentList: null,
    reversedAdjacentList: null
  },
  builder: {
    layout: {
      showQueryModifier: false,
      editingFilters: null,
      beingEdited: null
    },
    data: {
      selectedNodeName: null,
      rootNode: null,
      selectedNodes: null,
      availableNodes: []
    },
    statement: null
  },
  result: {
    visitingPath: [],
    indexedNodes: null,
    paging: {
      size: 10
    }
  }
})

const state = initialState

const getters = {}

const actions = {}

const mutations = {
  SET(state, payload) {
    if (payload.path === null) {
      state[payload.key] = payload.data
    } else {
      const ref = get(state, payload.path)
      ref[payload.key] = payload.data
    }
  },
  RESET(state, payload) {
    const initial = initialState()
    const r = get(initial, payload.path)
    const ref = get(state, payload.path)
    Object.keys(ref).forEach((key) => {
      ref[key] = r[key]
    })
  },
  RESET_ALL(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
