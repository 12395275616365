var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "logos",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
      },
    },
    [
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "8.59",
          y: "18.02",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(9.21 -2.96) rotate(25.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "12.35",
          y: "10.13",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(6.18 -5.35) rotate(25.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "2.29",
          y: "14.62",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(7.05 -.22) rotate(25.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "6.06",
          y: "6.72",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(4.02 -2.61) rotate(25.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "2.54",
          y: "10.32",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(17.37 11.38) rotate(115.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "6.79",
          y: "3.18",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(15.8 -1.91) rotate(115.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "10.46",
          y: "14.1",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(32.11 9.64) rotate(115.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "14.71",
          y: "6.96",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(30.54 -3.65) rotate(115.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "8.42",
          y: "8.21",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-1.93 15.07) rotate(-64.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "5.86",
          y: "15.35",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-10.31 16.06) rotate(-64.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "16.32",
          y: "11.98",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-.83 24.35) rotate(-64.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "13.76",
          y: "19.12",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(-9.21 25.34) rotate(-64.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "6.51",
          y: "12.18",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(11.64 28.7) rotate(-154.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "14.51",
          y: "15.6",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(23.79 38.27) rotate(-154.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "10.27",
          y: "4.29",
          width: "5.12",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(22.21 15.3) rotate(-154.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-2",
        attrs: {
          x: "18.28",
          y: "7.7",
          width: "3.43",
          height: "1.69",
          rx: ".85",
          ry: ".85",
          transform: "translate(34.36 24.87) rotate(-154.5)",
        },
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "0", y: "0", width: "24", height: "24" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }