// initial state
const state = {
  isDarkMode: false
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  switchDarkMode(state, mode) {
    state.isDarkMode = mode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
