import { render, staticRenderFns } from "./BlocksLoader.vue?vue&type=template&id=5a85e4df&scoped=true"
import script from "./BlocksLoader.vue?vue&type=script&lang=js"
export * from "./BlocksLoader.vue?vue&type=script&lang=js"
import style0 from "./BlocksLoader.vue?vue&type=style&index=0&id=5a85e4df&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a85e4df",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/SIMBAChain/simba-enterprise-platform/blocks-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a85e4df')) {
      api.createRecord('5a85e4df', component.options)
    } else {
      api.reload('5a85e4df', component.options)
    }
    module.hot.accept("./BlocksLoader.vue?vue&type=template&id=5a85e4df&scoped=true", function () {
      api.rerender('5a85e4df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/shared/state/loading/BlocksLoader.vue"
export default component.exports