var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_1217_94368)" } }, [
        _c("g", { attrs: { "clip-path": "url(#clip1_1217_94368)" } }, [
          _c("ellipse", {
            attrs: {
              cx: "8",
              cy: "8",
              rx: "8",
              ry: "8",
              transform: "rotate(180 8 8)",
              fill: "currentColor",
            },
          }),
          _c("path", {
            attrs: {
              d: "M11.8058 11.3941L8.41173 8L11.8058 4.60589",
              stroke: "white",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("path", {
            attrs: {
              d: "M7.00591 11.3941L3.6118 8L7.00591 4.60589",
              stroke: "white",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_1217_94368" } }, [
          _c("rect", { attrs: { width: "16", height: "16", fill: "white" } }),
        ]),
        _c("clipPath", { attrs: { id: "clip1_1217_94368" } }, [
          _c("rect", {
            attrs: {
              width: "16",
              height: "16",
              fill: "white",
              transform: "translate(16 16) rotate(-180)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }