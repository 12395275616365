import '@fortawesome/fontawesome-pro/scss/fontawesome.scss' // Ensure you are using css-loader
import '@fortawesome/fontawesome-pro/scss/brands.scss' // Ensure you are using css-loader
import '@fortawesome/fontawesome-pro/scss/solid.scss' // Ensure you are using css-loader
import '@fortawesome/fontawesome-pro/scss/regular.scss'
import '@fortawesome/fontawesome-pro/scss/light.scss'
import '@fortawesome/fontawesome-pro/scss/thin.scss'
import '@fortawesome/fontawesome-pro/scss/sharp-solid.scss'
import '@fortawesome/fontawesome-pro/scss/sharp-regular.scss'
import '@fortawesome/fontawesome-pro/scss/sharp-light.scss'
import IconCollapse from '@/components/icons/IconCollapse.vue'
import IconAzure from '@/components/icons/IconAzure.vue'
import IconAzureAlt from '@/components/icons/IconAzureAlt.vue'
import IconHLF from '@/components/icons/IconHLF.vue'
import IconHLFAlt from '@/components/icons/IconHLFAlt.vue'
import IconPolygon from '@/components/icons/IconPolygon.vue'
import IconQuorum from '@/components/icons/IconQuorum.vue'
import IconAWS from '@/components/icons/IconAWS.vue'
import IconIPFS from '@/components/icons/IconIPFS.vue'
import IconCompile from '@/components/icons/IconCompile.vue'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export const options = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          lighten5: '#FFFFFF',
          lighten4: '#C2E3FF',
          lighten3: '#A7C6E1',
          lighten2: '#8DAAC2',
          lighten1: '#728DA4',
          base: '#587086',
          darken1: '#3D5368',
          darken2: '#233749',
          darken3: '#081A2B', // primary-color: deep blue
          darken4: '#040D16',
          darken5: '#000000'
        },
        secondary: {
          lighten5: '#FFFFFF',
          lighten4: '#F3F4F4',
          lighten3: '#E7E9EA',
          lighten2: '#CACCCD',
          lighten1: '#ADAFB0',
          base: '#909292',
          darken1: '#747575',
          darken2: '#575758', // secondary-color: neutral grey
          darken3: '#3A3A3B',
          darken4: '#1D1D1D',
          darken5: '#000000'
        },
        accent: {
          lighten5: '#FFFFFF',
          lighten4: '#AAE1F8',
          lighten3: '#55C4F0',
          lighten2: '#00A6E9',
          lighten1: '#0086C7',
          base: '#0077A6', // tertiary-color: SIMBA blue
          darken1: '#005F85',
          darken2: '#004764',
          darken3: '#002F43',
          darken4: '#001821',
          darken5: '#000000'
        },
        error: {
          lighten5: '#FFFFFF',
          lighten4: '#FDD1D2',
          lighten3: '#FCA3A6',
          lighten2: '#FA7479',
          lighten1: '#F8464C',
          base: '#DD122A', // error-color: high hazard
          darken1: '#C20014',
          darken2: '#92000F',
          darken3: '#61000A',
          darken4: '#310005',
          darken5: '#000000'
        },
        info: {
          lighten5: '#FFFFFF',
          lighten4: '#AAE6E8',
          lighten3: '#55CCD1',
          lighten2: '#00B3BA',
          lighten1: '#00999F',
          base: '#008085', // info-color: pacific
          darken1: '#C20014',
          darken2: '#92000F',
          darken3: '#61000A',
          darken4: '#310005',
          darken5: '#000000'
        },
        success: {
          lighten5: '#FFFFFF',
          lighten4: '#E5F3DC',
          lighten3: '#CBE7BA',
          lighten2: '#B2DB97',
          lighten1: '#98CF75',
          base: '#7EC352',
          darken1: '#659C42',
          darken2: '#4C7531', // success-color: melon
          darken3: '#324E21',
          darken4: '#192710',
          darken5: '#000000'
        },
        warning: {
          lighten5: '#FFFFFF',
          lighten4: '#FEDC80',
          lighten3: '#FCB800',
          lighten2: '#FC8F00',
          lighten1: '#FB6600',
          base: '#DA4E26',
          darken1: '#AE3E1E', // warning-color: sunrise
          darken2: '#832F17',
          darken3: '#571F0F',
          darken4: '#2C1008',
          darken5: '#000000'
        },
        background: '#FFFFFF'
      },
      dark: {
        primary: '#00A6E9',
        secondary: {
          lighten5: '#FFFFFF',
          lighten4: '#F3F4F4',
          lighten3: '#E7E9EA',
          lighten2: '#CACCCD',
          lighten1: '#ADAFB0',
          base: '#909292',
          darken1: '#747575',
          darken2: '#575758', // secondary-color: neutral grey
          darken3: '#3A3A3B',
          darken4: '#1D1D1D',
          darken5: '#000000'
        },
        accent: {
          lighten5: '#FFFFFF',
          lighten4: '#AAE1F8',
          lighten3: '#55C4F0',
          lighten2: '#00A6E9',
          lighten1: '#0086C7',
          base: '#0077A6', // tertiary-color: SIMBA blue
          darken1: '#005F85',
          darken2: '#004764',
          darken3: '#002F43',
          darken4: '#001821',
          darken5: '#000000'
        },
        error: '#DD122A',
        info: {
          lighten5: '#FFFFFF',
          lighten4: '#AAE6E8',
          lighten3: '#55CCD1',
          lighten2: '#00B3BA',
          lighten1: '#00999F',
          base: '#008085', // info-color: pacific
          darken1: '#C20014',
          darken2: '#92000F',
          darken3: '#61000A',
          darken4: '#310005',
          darken5: '#000000'
        },
        success: {
          lighten5: '#FFFFFF',
          lighten4: '#E5F3DC',
          lighten3: '#CBE7BA',
          lighten2: '#B2DB97',
          lighten1: '#98CF75',
          base: '#7EC352',
          darken1: '#659C42',
          darken2: '#4C7531', // success-color: melon
          darken3: '#324E21',
          darken4: '#192710',
          darken5: '#000000'
        },
        warning: {
          lighten5: '#FFFFFF',
          lighten4: '#FEDC80',
          lighten3: '#FCB800',
          lighten2: '#FC8F00',
          lighten1: '#FB6600',
          base: '#DA4E26',
          darken1: '#AE3E1E', // warning-color: sunrise
          darken2: '#832F17',
          darken3: '#571F0F',
          darken4: '#2C1008',
          darken5: '#000000'
        },
        background: '#000000'
      }
    }
  },
  icons: {
    iconfont: 'fa',
    values: {
      complete: '...',
      cancel: 'fa-regular fa-circle-xmark',
      close: 'fa-solid fa-xmark',
      refresh: 'fa-solid fa-refresh',
      delete: 'fa-regular fa-trash-can',
      clear: '...',
      success: '...',
      info: '...',
      warning: '...',
      error: '...',
      prev: 'fa-solid fa-chevron-left',
      next: 'fa-solid fa-chevron-right',
      checkboxOn: 'fa-solid fa-square-check',
      checkboxOff: 'fa-regular fa-square',
      radioOn: 'fa-solid fa-circle-check',
      radioOff: 'fa-regular fa-circle',
      checkmark: 'fa-regular fa-check',
      checkboxIndeterminate: 'fa-regular fa-square-minus',
      delimiter: '...',
      sort: 'fa-solid fa-arrow-up',
      expand: 'fa-solid fa-chevron-down',
      menu: '...',
      list: 'fa-solid fa-list',
      subgroup: '...',
      dropdown: 'fa-solid fa-caret-down',
      logout: 'fa-regular fa-arrow-right-from-bracket',
      edit: '...',
      ratingEmpty: '...',
      ratingFull: '...',
      ratingHalf: '...',
      loading: 'fa-solid fa-circle-notch fa-spin',
      first: 'fa-solid fa-chevrons-left',
      last: 'fa-solid fa-chevrons-right',
      unfold: '...',
      file: '...',
      ethereum: 'fa-brands fa-ethereum',
      polygon: {
        component: IconPolygon
      },
      quorum: {
        component: IconQuorum
      },
      hlf: {
        component: IconHLF
      },
      hlfDark: {
        component: IconHLFAlt
      },
      blockchain: 'fa-regular fa-cubes',
      network: 'fa-regular fa-network-wired',
      storage: 'fa-regular fa-server',
      address: 'fa-regular fa-location-dot',
      event: 'fa-solid fa-bell',
      filter: 'fa-solid fa-filter',
      role: 'fa-solid fa-user-lock',
      calendar: 'fa-solid fa-calendar-clock',
      contract: 'fa-solid fa-scroll',
      transaction: 'fa-solid fa-right-left',
      scd: 'fa-solid fa-compass-drafting',
      gql: 'fa-solid fa-chart-radar',
      explorer: 'fa-solid fa-magnifying-glass',
      subscriber: 'fa-solid fa-bell-on',
      scdAddAsset: 'fa-solid fa-square-plus',
      scdAddTransaction: 'fa-solid fa-circle-plus',
      scdAddRelation: 'fa-solid fa-arrow-up-right',
      method: 'fa-regular fa-function',
      collapse: {
        component: IconCollapse
      },
      azure: {
        component: IconAzure
      },
      azureAlt: {
        component: IconAzureAlt
      },
      aws: {
        component: IconAWS
      },
      ipfs: {
        component: IconIPFS
      },
      compile: {
        component: IconCompile
      },
      browser: 'fa-light fa-browser',
      contractOutline: 'fa-regular fa-scroll'
    }
  }
}
export default new Vuetify(options)
