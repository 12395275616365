var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-banner",
    {
      class: _vm.bannerData.bannerClass,
      style: _vm.bannerData.bannerStyle,
      attrs: { "mobile-breakpoint": _vm.bannerMobileBreakpoint },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function () {
            return [
              _c(
                "v-icon",
                {
                  class: `on-${_vm.bannerData.bannerClass}`,
                  attrs: { small: "" },
                },
                [_vm._v(" " + _vm._s(_vm.bannerData.icon) + " ")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function ({ dismiss }) {
            return [
              _vm.bannerData.dismissible
                ? _c(
                    "v-btn",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "50%",
                        right: "20px",
                        transform: "translate(0%, -50%)",
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.dismissBanner(_vm.bannerData, dismiss)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { class: `on-${_vm.bannerData.bannerClass}` },
                        [_vm._v("fa-regular fa-times")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c("div", { staticClass: "banner-text" }, [
        _vm.bannerData.type === "darkmode"
          ? _c("div", [
              _vm.isDarkMode
                ? _c("div", [
                    _c("span", { staticClass: "font-weight-semibold" }, [
                      _vm._v("Dark Mode is in BETA. "),
                    ]),
                    _c("span", [_vm._v("Notice something? ")]),
                    _c(
                      "a",
                      {
                        staticClass: "on-info-container banner-link",
                        attrs: {
                          href: "https://support.simbachain.com/knowledge",
                          target: "_blank",
                        },
                      },
                      [_c("span", [_vm._v(" Submit Feedback")])]
                    ),
                    _c("span", [_vm._v(" or ")]),
                    _c(
                      "span",
                      {
                        staticClass: "text-decoration-underline banner-link",
                        on: {
                          click: function ($event) {
                            _vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark
                          },
                        },
                      },
                      [_vm._v(" Switch to Light Mode ")]
                    ),
                  ])
                : _vm._e(),
              !_vm.isDarkMode
                ? _c("div", [
                    _c("span", [_vm._v("You've switched to Light Mode. ")]),
                    _c(
                      "span",
                      {
                        staticClass: "text-decoration-underline banner-link",
                        on: {
                          click: function ($event) {
                            _vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark
                          },
                        },
                      },
                      [_vm._v(" Switch back to Dark Mode (BETA) ")]
                    ),
                  ])
                : _vm._e(),
            ])
          : _c("span", [
              _vm._v(" " + _vm._s(_vm.formattedMessage) + " "),
              _vm.bannerData.alertCount > 1
                ? _c("span", [
                    _vm._v("(and "),
                    _c("span", { staticClass: "text-decoration-underline" }, [
                      _vm._v(
                        _vm._s(_vm.bannerData.alertCount - 1) +
                          " other" +
                          _vm._s(_vm.bannerData.alertCount > 2 ? "s" : "")
                      ),
                    ]),
                    _vm._v(")"),
                  ])
                : _vm._e(),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }