import get from 'lodash/get'

const initialState = () => ({
  drawer: {
    data: null
  },
  organization: {
    loading: false,
    data: null
  },
  dialog: {
    show: false,
    component: null,
    data: {
      title: null,
      subtitle: null
    },
    param: null
  },
  explorer: {
    dialog: {
      show: false,
      component: null,
      data: {
        title: null,
        subtitle: null,
        width: null,
        persistent: false
      },
      param: null
    }
  },
  gql: {
    dialog: {
      show: false,
      component: null,
      data: {
        title: null,
        subtitle: null,
        width: null,
        persistent: false
      },
      param: null
    }
  }
})

const state = initialState

const getters = {}

const actions = {}

const mutations = {
  SET(state, payload) {
    if (payload.path === null) {
      state[payload.key] = payload.data
    } else {
      const ref = get(state, payload.path)
      ref[payload.key] = payload.data
    }
  },
  RESET(state, payload) {
    const initial = initialState()
    const r = get(initial, payload.path)
    const ref = get(state, payload.path)
    Object.keys(ref).forEach((key) => {
      ref[key] = r[key]
    })
  },
  RESET_ALL(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
