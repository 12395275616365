var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "banner-container",
      style: {
        height:
          _vm.banners.length > 0
            ? `${_vm.banners.length * _vm.bannerHeightFactor * 41}px`
            : "",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { position: "fixed", "z-index": "5", width: "100%" } },
        _vm._l(_vm.banners, function (banner, index) {
          return _c("BlocksBanner", {
            key: index,
            staticClass: "banner d-block",
            attrs: { "banner-data": banner, locale: _vm.alertLocale },
            on: {
              "dismiss-banner": function ($event) {
                return _vm.dismissBanner(banner)
              },
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }