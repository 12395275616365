import { render, staticRenderFns } from "./BlocksBanner.vue?vue&type=template&id=50c8ca01"
import script from "./BlocksBanner.vue?vue&type=script&lang=js"
export * from "./BlocksBanner.vue?vue&type=script&lang=js"
import style0 from "./BlocksBanner.vue?vue&type=style&index=0&id=50c8ca01&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/SIMBAChain/simba-enterprise-platform/blocks-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50c8ca01')) {
      api.createRecord('50c8ca01', component.options)
    } else {
      api.reload('50c8ca01', component.options)
    }
    module.hot.accept("./BlocksBanner.vue?vue&type=template&id=50c8ca01", function () {
      api.rerender('50c8ca01', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/shared/banner/blocksBanner/BlocksBanner.vue"
export default component.exports